/* eslint-disable-next no-shadow */
export const CHECKOUT_WIDGET_ID = '14fd5970-8072-c276-1246-058b79e70c1a';

export enum FedopsInteractions {
  ApplyCouponInteraction = 'apply-coupon-interaction',
  ApplyGiftCardInteraction = 'apply-gift-card-interaction',
  PlaceOrderInteraction = 'place-order-interaction',
  ValidatePaymentInteraction = 'validate-payment-interaction',
  InitializePaymentInPlaceOrderInteraction = 'initialize-payment-in-place-order-interaction',
  ChangeDeliveryMethodInteraction = 'change-delivery-method-interaction',
  SubmitCustomerDetailsInteraction = 'submit-customer-details-interaction',
  InitFormsInteraction = 'init-forms-interaction',
  FetchCheckout = 'fetch-checkout',
  SetBillingAndPaymentInteraction = 'set-billing-and-payment-interaction',
}

export const SPECS = {
  AddSlotToCheckout: 'specs.stores.AddSlotToCheckout',
  DontRedirectToSiteOnFailedFetch: 'specs.stores.DontRedirectToSiteOnFailedFetch',
  ShowLoaderWhileLoadingNewCheckout: 'specs.stores.ShowLoaderWhileLoadingNewCheckout',
  CheckoutExcludeGoogleAutoComplete: 'specs.stores.CheckoutExcludeGoogleAutoComplete',
  ShouldNotAggregateOptionsWhenDeliveryTimeIsUndefined:
    'specs.stores.ShouldNotAggregateOptionsWhenDeliveryTimeIsUndefined',
  MergePaymentAndPlaceOrderSteps: 'specs.stores.MergePaymentAndPlaceOrderSteps',
  ShouldFetchCustomSettingsAndCustomizeCheckoutLayout:
    'specs.stores.ShouldFetchCustomSettingsAndCustomizeCheckoutLayout',
  ShouldUsePanorama: 'specs.stores.ShouldUsePanorama',
  ShouldAlwaysShowAddShippingAddressForPickup: 'specs.stores.ShouldAlwaysShowAddShippingAddressForPickup',
  FilterCountriesByShippingCountries: 'specs.stores.FilterCountriesByShippingCountries',
  ShouldSplitBillingInfoPrefill: 'specs.stores.ShouldSplitBillingInfoPrefill',
  SlotSpacingAndDividers: 'specs.stores.SlotSpacingAndDividers',
  UseSingleCustomerDetailsForm: 'specs.stores.UseSingleCustomerDetailsForm',
  UseSingleCustomerDetailsFormWithComposer: 'specs.stores.UseSingleCustomerDetailsFormWithComposer',
  HideBillingFormForPayPalAndManualNotBrazil: 'specs.stores.HideBillingFormForPayPalAndManualNotBrazil',
  WithCheckoutHackForReact18Tests: 'specs.stores.WithCheckoutHackForReact18Tests',
  FixSubscriptionLineItemText: 'specs.stores.FixSubscriptionLineItemText',
  RemoveCheckoutWordWhenUsingLogo: 'specs.stores.RemoveCheckoutWordWhenUsingLogo',
  DontUnsubscribeOnCheckoutFlow: 'specs.stores.DontUnsubscribeOnCheckoutFlow',
  ChangePlaceOrderButtonColor: 'specs.stores.ChangePlaceOrderButtonColor',
  GiftCartSeviceDownCheckout: 'specs.stores.GiftCartSeviceDownCheckout',
  ShowTaxBreakdownInCheckout: 'specs.stores.ShowTaxBreakdownInCheckout',
  ShouldResetMemberStateEditMode: 'specs.stores.ShouldResetMemberStateEditMode',
  FixTimeSlotsBug: 'specs.stores.FixTimeSlotsBug',
};

export enum FieldMasks {
  customField = 'customFields',
  extendedFields = 'extendedFields',
  buyerInfoEmail = 'buyerInfo.email',
  shippingContact = 'shippingInfo.shippingDestination.contactDetails',
  shippingAddress = 'shippingInfo.shippingDestination.address',
  shippingAddressesServiceId = 'shippingInfo.shippingDestination.addressesServiceId',
  billingContact = 'billingInfo.contactDetails',
  billingAddress = 'billingInfo.address',
  billingAddressesServiceId = 'billingInfo.addressesServiceId',
  selectedCarrierServiceOption = 'shippingInfo.selectedCarrierServiceOption',
}

export enum AddressesFieldMasks {
  firstName = 'fullName.firstName',
  lastName = 'fullName.lastName',
  company = 'company',
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  country = 'country',
  subdivision = 'subdivision',
  zipCode = 'zipCode',
  phoneNumber = 'phoneNumber',
  setAsDefault = 'setAsDefault',
  taxId = 'taxInfo.id',
  taxType = 'taxInfo.type',
  streetName = 'street.name',
  streetNumber = 'street.number',
}

export const FIELDS_GAP = 20;

export const MAX_ITEM_OPTION_LENGTH = 500;

export const THUMBNAIL_WIDTH = 60;

export const DEFAULT_IMAGE_ICON_SIZE = 33;

export const NUMBER_OF_OPTIONS_TO_SHOW_BEFORE_COLLAPSE = 0;

export const DELIVERY_METHOD_GROUP_NAME = 'deliveryOptions';

export const MAX_CUSTOM_FIELD_VALUE_LENGTH = 250;
export const MAX_ZIP_CODE_FIELD_LENGTH = 250;

export const ONE_COLUMN_VIEW_WIDTH_THRESHOLD = 750;

export const PAYPAL = 'PayPal';

export const FORMS_TEMPLATE_IDS = {
  EMAIL: 'b739c372-940c-4148-8d13-9f139a662fa2',
  CONTACT: '5c1d74e6-ccaa-4e2f-9f9f-fb620ef73446',
  ADDITIONAL_INFO: 'afb63805-2e62-495e-969c-e45191af3818',
  ADDRESS: 'd5993645-4531-4705-9172-186be64aa429',
  VAT_ID: '90553971-6055-4a40-a746-75c8996dfe36',
  BILLING: '40f8c94a-07d8-453b-b470-d26508a8da97',
  CUSTOMER_DETAILS_W_ADDITIONAL_INFO: 'a19435ae-880c-4f83-8062-3e8b19832a54',
  CUSTOMER_DETAILS_WO_ADDITIONAL_INFO: '3f63d41e-9b8e-4631-bfe0-e984965ad618',
};

export const FORMS_TEMPLATE_IDS_USED_WITH_CHECKOUT_COMPOSER = {
  EMAIL: '6bddf2de-2577-45c9-8a35-71c4f4695210',
  CONTACT: '73260314-14b4-4047-ad8c-b1998b2fa8e1',
  ADDRESS: '7fad480d-1dcd-4c95-a4af-714a741dd5a7',
  EXTENDED_FIELDS_FORM_ID: '391eeac7-0183-4955-8663-99bb01ab0ab4',
};

export const FORMS_CHECKOUT_NAMESPACE = 'wix.ecom.v1.checkout';
export const FORMS_CHECKOUT_DEXT_FQDN = 'wix.ecom.*.checkout';

export const DEFAULT_COUNTRY = 'US';
export const GROO_DEFAULT_COUNTRY = 'IL';

export const COUNTRIES_WITH_VAT = ['BR'];

export const ADD_NEW_ADDRESS_ID = 'addNewAddress';

export enum THEMES {
  DONATIONS = 'donations',
}
